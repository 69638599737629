import * as Sentry from '@sentry/browser';

export { Sentry };
 
(window as any).captureException = Sentry.captureException;

export function initSentry(dsn: string) {
	Sentry.init({
		dsn,
	});
}
