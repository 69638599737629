import { SiteContext } from '../context.js';
import { setCookie } from '../../common/util.js';

/**
 * @deprecated This is basically broken due to CHIPS and shit, revert to top-level redirects for now, probably remove later.
 */
export async function checkActiveAuthToken(sc: SiteContext) {

	const user = sc.user.peek();
	const { authHost, cookies } = sc.context.peek();
	
	if (user) return;
	
	const res = await fetch(`//${authHost}/auth/active`, {
		credentials: 'include',
		mode: 'cors',
	});
	if (!res.ok) return;
	
	const json: {
		cookie?: string;
		remember?: boolean;
	} = await res.json();
	
	if (json.cookie && json.cookie != cookies.auth) {
		// authHost logged in with a (different) valid cookie
		setCookie('auth', json.cookie, json.remember ? 86400 * 365 : 0);
		location.reload();
	}
	
}
